<template>
  <div
    class="drop-zone bg-light px-3 py-5 rounded-3"
    style="border: dotted 3px #eee"
    @drop="onDrop($event, 1)"
    @dragover.prevent
    @dragenter.prevent
  >
    <!-- <div class="text-center">
      <i class="bi bi-cloud-arrow-up-fill" style="font-size: 2rem"></i>
    </div> -->
    <div class="flex justify-center">
      <i class="bi bi-folder-fill" style="color: #003282"></i>
    </div>
    <p class="text-center text-xs">
      <span v-if="dnd">Drag-and-drop file to upload or add file or</span>
      <button
        type="button"
        @click="triggerFilePicker"
        class="
                    block
                    mx-auto
                    hover:bg-blue-800
                    hover:text-white
                    bg-transparent
                    border border-blue-800
                    text-blue-800
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-2
                    px-4
                    mt-3
                    rounded
                  "
        style="width:inherit"
      >
        Add File
      </button>
      <input
        type="file"
        name="file"
        ref="file"
        class="hide"
        :accept="acceptParams"
        @change="handleFileSelect"
        :multiple="allowMultiple"
      />
    </p>
  </div>
</template>

<script>
export default {
  name: "DraggableComponent",
  props: {
    acceptParams: {
      default: ".csv",
      type: String,
    },
    allowMultiple: {
      default: false,
      type: Boolean,
    },
    dnd: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    onDrop(evt) {
      evt.stopPropagation();
      evt.preventDefault();

      var file = evt.dataTransfer.files;

      console.log(file, "Working");

      if (this.allowMultiple) {
        // var file = evt.target.files;
        this.$emit("handleFile", file);
      } else {
        console.log("Handled file select");
        // var file = file[0];
        this.$emit("handleFile", file[0]);
      }
      //   doUpload(files[0]);
    },
    triggerFilePicker() {
      this.$refs.file.click();
    },
    handleFileSelect(evt) {
      if (this.allowMultiple) {
        var file = evt.target.files;
        this.$emit("handleFile", file);
      } else {
        console.log("Handled file select");
        var file = evt.target.files[0];
        this.$emit("handleFile", file);
      }
    },
  },
};
</script>

<style>
.hide {
  display: none;
}
</style>
